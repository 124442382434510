import React from 'react';
import clsx from 'clsx';
import { Theme } from '@material-ui/core/styles';
import Button, { ButtonProps } from '@material-ui/core/Button';
import Loader from '../Loader';
import LoaderDark from '../Loader/LoaderDark';

import withDefaults from '../withDefaults';

const styles = (theme: Theme) => ({
  root: {
    padding: '16px 24px',
    height: '48px',
    borderRadius: 0,
    fontSize: '12px',
    fontWeight: '500',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '16px',
    letterSpacing: '0.1em',
    fontFamily: theme.bodyFontFamily,
    textTransform: 'uppercase',
  },
  primary: {
    color: 'white',
    backgroundColor: ({ disabled }: ButtonProps) =>
      disabled ? theme.palette.greyMedium.main : theme.palette.primary.main,
    '&:hover': {
      backgroundColor: ({ disabled }: ButtonProps) =>
        disabled ? theme.palette.greyMedium.main : theme.palette.primary.light,
    },
    '&.MuiButton-root:hover.Mui-disabled': {
      backgroundColor: theme.palette.greyMedium.main,
    },
  },
  secondary: {
    color: ({ disabled }: ButtonProps) =>
      disabled ? theme.palette.greyDark.main : theme.palette.primary.main,
    backgroundColor: 'white',
    border: ({ disabled }: ButtonProps) =>
      disabled
        ? `1px solid ${theme.palette.greyDark.main}`
        : `1px solid ${theme.palette.primary.main}`,
    '&:hover': {
      backgroundColor: ({ disabled }: ButtonProps) =>
        disabled ? 'white' : theme.palette.greyLight.main,
      border: ({ disabled }: ButtonProps) =>
        disabled
          ? `1px solid ${theme.palette.greyDark.main}`
          : `1px solid ${theme.palette.greyLight.main}`,
    },
    '&.MuiButton-root:hover.Mui-disabled': {
      backgroundColor: 'white',
    },
  },
  tertiary: {
    color: ({ disabled }: ButtonProps) =>
      disabled ? theme.palette.greyDark.main : theme.palette.primary.main,
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: ({ disabled }: ButtonProps) =>
        disabled ? 'white' : theme.palette.greyLight.main,
    },
    '&.MuiButton-root:hover.Mui-disabled': {
      backgroundColor: 'white',
    },
  },
  addToCart: {
    height: '40px !important',
    color: ({ disabled }: ButtonProps) =>
      disabled
        ? `${theme.palette.activeGrey.main} !important`
        : theme.palette.primary.main,
    backgroundColor: ({ disabled }: ButtonProps) =>
      disabled
        ? theme.palette.greyLight.main
        : theme.palette.bronzeNeutral3.main,
    '&:hover': {
      backgroundColor: ({ disabled }: ButtonProps) =>
        disabled
          ? theme.palette.greyLight.main
          : theme.palette.bronzeNeutral3.main,
    },
    '&.MuiButton-root:hover.Mui-disabled': {
      backgroundColor: theme.palette.greyMedium.main,
    },
  },
  addToCartIcon: {
    height: '40px !important',
    color: ({ disabled }: ButtonProps) =>
      disabled ? theme.palette.greyDark.main : theme.palette.primary.main,
    backgroundColor: 'white',
    border: ({ disabled }: ButtonProps) =>
      disabled
        ? `1px solid ${theme.palette.greyDark.main}`
        : `1px solid ${theme.palette.bronzeNeutral3.main}`,
    '&:hover': {
      backgroundColor: `${theme.palette.barelyThereGrey.main}`,
      border: ({ disabled }: ButtonProps) =>
        disabled
          ? `1px solid ${theme.palette.greyDark.main}`
          : `1px solid ${theme.palette.bronzeNeutral3.main}`,
    },
  },
  mud: {
    backgroundColor: ({ disabled }: ButtonProps) =>
      disabled ? theme.palette.greyMedium.main : theme.palette.mud.main,
    '&:hover': {
      backgroundColor: ({ disabled }: ButtonProps) =>
        disabled ? theme.palette.greyMedium.main : theme.palette.mud.light,
    },
    '&.MuiButton-root:hover.Mui-disabled': {
      backgroundColor: theme.palette.greyMedium.main,
    },
  },
  blue: {
    backgroundColor: ({ disabled }: ButtonProps) =>
      disabled ? theme.palette.greyMedium.main : theme.palette.blue.main,
    '&:hover': {
      backgroundColor: ({ disabled }: ButtonProps) =>
        disabled
          ? theme.palette.greyMedium.main
          : `${theme.palette.darkBlue100.main}`,
    },
    '&.MuiButton-root:hover.Mui-disabled': {
      backgroundColor: theme.palette.greyMedium.main,
    },
  },
  red: {
    backgroundColor: ({ disabled }: ButtonProps) =>
      disabled
        ? theme.palette.greyMedium.main
        : theme.palette.secondaryRed.main,
    '&:hover': {
      backgroundColor: ({ disabled }: ButtonProps) =>
        disabled
          ? theme.palette.greyMedium.main
          : `${theme.palette.deepRedText.main}`,
    },
    '&.MuiButton-root:hover.Mui-disabled': {
      backgroundColor: theme.palette.greyMedium.main,
    },
  },
  bronzeNeutral3: {
    color: ({ disabled }: ButtonProps) =>
      disabled ? theme.palette.greyDark.main : theme.palette.primary.main,
    border: ({ disabled }: ButtonProps) =>
      disabled
        ? `1px solid ${theme.palette.greyDark.main}`
        : `1px solid ${theme.palette.bronzeNeutral3.main}`,
    backgroundColor: ({ disabled }: ButtonProps) =>
      disabled
        ? theme.palette.greyMedium.main
        : theme.palette.bronzeNeutral3.main,
    '&:hover': {
      color: ({ disabled }: ButtonProps) =>
        disabled ? theme.palette.greyDark.main : theme.palette.primary.main,
      border: ({ disabled }: ButtonProps) =>
        disabled
          ? `1px solid ${theme.palette.greyDark.main}`
          : `1px solid ${theme.palette.bronzeNeutral3.main}`,
      backgroundColor: ({ disabled }: ButtonProps) =>
        disabled
          ? theme.palette.greyMedium.main
          : theme.palette.bronzeNeutral3.main,
    },
    '&.MuiButton-root:hover.Mui-disabled': {
      backgroundColor: theme.palette.greyMedium.main,
    },
  },
  // black text, mud underline. doesn't exist in design system, so will need to one-off this!
  underlined: {
    color: ({ disabled }: ButtonProps) =>
      disabled ? theme.palette.greyMedium.main : theme.palette.primary.main,
    padding: '0px',
    height: '21px',
    paddingBottom: '6px',
    backgroundColor: 'transparent',
    borderBottom: ({ disabled }: ButtonProps) =>
      disabled
        ? `2px solid ${theme.palette.greyMedium.main}`
        : `2px solid ${theme.palette.mud.main}`,
    fontSize: '14px',
    lineHeight: '21px',
    minWidth: '20px',
    '&:hover': {
      color: ({ disabled }: ButtonProps) =>
        disabled ? theme.palette.greyMedium.main : theme.palette.primary.light,
      backgroundColor: 'transparent',
      borderBottom: ({ disabled }: ButtonProps) =>
        disabled
          ? `2px solid ${theme.palette.greyMedium.main}`
          : `2px solid ${theme.palette.mud.light}`,
    },
  },
  // mud text, mud underline
  underlineMud: {
    color: ({ disabled }: ButtonProps) =>
      disabled ? theme.palette.greyMedium.main : theme.palette.mud.main,
    height: '21px',
    fontSize: '14px',
    lineHeight: '21px',
    border: 'none',
    padding: '0px',
    paddingBottom: '6px',
    minWidth: '20px',
    backgroundColor: 'transparent',
    borderBottom: ({ disabled }: ButtonProps) =>
      disabled
        ? `2px solid ${theme.palette.greyMedium.main}`
        : `2px solid ${theme.palette.mud.main}`,
    '&:hover': {
      backgroundColor: 'transparent',
      color: ({ disabled }: ButtonProps) =>
        disabled ? theme.palette.greyMedium.main : theme.palette.mud.light,
      borderBottom: ({ disabled }: ButtonProps) =>
        disabled
          ? `2px solid ${theme.palette.greyMedium.main}`
          : `2px solid ${theme.palette.mud.light}`,
    },
  },
  secondaryRed: {
    backgroundColor: 'transparent',
    color: theme.palette.deepRedText.main,
    border: ({ disabled }: ButtonProps) =>
      disabled
        ? `1px solid ${theme.palette.greyDark.main}`
        : `1px solid ${theme.palette.deepRedText.main}`,
    '&:hover': {
      backgroundColor: ({ disabled }: ButtonProps) =>
        disabled ? theme.palette.greyMedium.main : 'white',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '16px 18px',
    },
  },
  secondaryBlue: {
    backgroundColor: 'transparent',
    color: theme.palette.blue.main,
    border: ({ disabled }: ButtonProps) =>
      disabled
        ? `1px solid ${theme.palette.greyDark.main}`
        : `1px solid ${theme.palette.blue.main}`,
    '&:hover': {
      backgroundColor: ({ disabled }: ButtonProps) =>
        disabled ? theme.palette.greyMedium.main : 'white',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '16px 18px',
    },
  },
  hero: {
    height: `56px`,
    fontSize: '16px',
    fontWeight: '400',
  },
  primarySmall: {
    color: 'white',
    height: '33px',
    borderRadius: '36px',
    backgroundColor: ({ disabled }: ButtonProps) =>
      disabled ? theme.palette.greyMedium.main : theme.palette.primary.main,
    '&:hover': {
      backgroundColor: ({ disabled }: ButtonProps) =>
        disabled ? theme.palette.greyMedium.main : theme.palette.primary.light,
    },
    '&.MuiButton-root:hover.Mui-disabled': {
      backgroundColor: theme.palette.greyMedium.main,
    },
  },
  primaryMaxWidth: {
    color: 'white',
    maxWidth: ({ size }: ButtonProps) => size,
    backgroundColor: ({ disabled }: ButtonProps) =>
      disabled ? theme.palette.greyMedium.main : theme.palette.primary.main,
    '&:hover': {
      backgroundColor: ({ disabled }: ButtonProps) =>
        disabled ? theme.palette.greyMedium.main : theme.palette.primary.light,
    },
    '&.MuiButton-root:hover.Mui-disabled': {
      backgroundColor: theme.palette.greyMedium.main,
    },
  },
  secondarySmall: {
    color: ({ disabled }: ButtonProps) =>
      disabled ? theme.palette.greyDark.main : theme.palette.primary.main,
    backgroundColor: 'white',
    height: '33px',
    borderRadius: '36px',
    border: ({ disabled }: ButtonProps) =>
      disabled
        ? `1px solid ${theme.palette.greyDark.main}`
        : `1px solid ${theme.palette.primary.main}`,
    '&:hover': {
      backgroundColor: ({ disabled }: ButtonProps) =>
        disabled ? 'white' : theme.palette.greyLight.main,
      border: ({ disabled }: ButtonProps) =>
        disabled
          ? `1px solid ${theme.palette.greyDark.main}`
          : `1px solid ${theme.palette.greyLight.main}`,
    },
    '&.MuiButton-root:hover.Mui-disabled': {
      backgroundColor: 'white',
    },
  },
});

const OHWButton = ({
  color = 'primary',
  classes,
  type = 'primary',
  variation = 'contained',
  disabled = false,
  className: classNamesProp,
  loading,
  size,
  children,
  ...rest
}: ButtonProps) => {
  const className = clsx(
    classes.root,
    {
      [classes.primary]: type === 'primary',
      [classes.secondary]: type === 'secondary',
      [classes.addToCart]: type === 'addToCart',
      [classes.addToCartIcon]: type === 'addToCartIcon', // TODO(gemma): ButtonV2 this is the plus/minus part of the button. thinking
      [classes.hero]: variation === 'hero', // NOW DEPRECATED - use ButtonV2
      [classes.mud]: color === 'mud',
      [classes.blue]: color === 'blue', // not used (DO NOT USE)
      [classes.red]: color === 'red', // not used (DO NOT USE)
      [classes.bronzeNeutral3]: color === 'bronzeNeutral3', // NOW DEPRECATED - use ButtonV2
      [classes.underlined]: type === 'underlined', // TODO(gemma): ButtonV2 Noted all the ones that need sorting out the black/mud combo
      [classes.underlineMud]: type === 'underlineMud', // NOW DEPRECATED - use ButtonV2
      [classes.secondaryRed]: type === 'secondaryRed', // not used (DO NOT USE)
      [classes.secondaryBlue]: type === 'secondaryBlue', // not used (DO NOT USE)
      [classes.primarySmall]: type === 'primarySmall', // NOW DEPRECATED - use ButtonV2
      [classes.primaryMaxWidth]: type === 'primaryMaxWidth', // TODO(gemma): ButtonV2 on hold bc likely to conflict with maxine's work - check which work and if that's all done
      [classes.secondarySmall]: type === 'secondarySmall', // NOW DEPRECATED - use ButtonV2
    },
    classNamesProp,
  );
  return (
    <Button
      className={className}
      disabled={disabled}
      variation={variation}
      size={size}
      disableRipple
      {...rest}
    >
      {!loading && children}
      {loading && type !== 'secondary' && <Loader />}
      {loading && type === 'secondary' && <LoaderDark />}
    </Button>
  );
};

export default withDefaults(styles, { name: 'OH-Button' })(OHWButton);
