import React from 'react';
import clsx from 'clsx';
import Typography, { TypographyProps } from '@material-ui/core/Typography';
import { StylesProvider, Theme } from '@material-ui/core';
import withDefaults from '../withDefaults';
import { TEXT_DS } from './constants';

type TypographyStylePropsType = {
  size?: string;
  weight?: string;
  decoration?: string;
  typographyName?: string; // the original figma typography name
};

// constants for max and min calculated
// font-size based on viewport
// const maximumViewport = 1440;
// const minimumViewport = 320;

// calculate fluid type font-size
// const calcFontSize = (maxPixel: number, minPixel: number) => ({
//   fontSize: `${minPixel}px`,
//   [`@media (min-width: ${minimumViewport}px)`]: {
//     fontSize: `calc(${minPixel}px + (${maxPixel} - ${minPixel}) * ((100vw - ${minimumViewport}px) / (${maximumViewport} - ${minimumViewport})))`,
//   },
//   [`@media (min-width: ${maximumViewport}px)`]: {
//     fontSize: `${maxPixel}px`,
//   },
// });

const styles = (theme: Theme) => ({
  root: {
    fontFamily: theme.bodyFontFamily,
  },
  h1: {
    fontWeight: 300,
    fontFamily: theme.headerFontFamily,
    margin: '0px',
    fontSize: '32px',
    lineHeight: '44px',
    letterSpacing: '0.03em',
    [theme.breakpoints.up('sm')]: {
      fontSize: '44px',
      lineHeight: '60px',
    },
    [theme.breakpoints.up('lg')]: {
      letterSpacing: '0.025em',
      fontSize: '60px',
      lineHeight: '74px',
    },
  },
  h2: {
    fontWeight: 300,
    fontFamily: theme.headerFontFamily,
    margin: '0px',
    fontSize: '24px',
    lineHeight: '36px',
    letterSpacing: '0.04em',
    [theme.breakpoints.up('sm')]: {
      fontSize: '32px',
      lineHeight: '44px',
    },
    [theme.breakpoints.up('lg')]: {
      letterSpacing: '0.03em',
      fontSize: '44px',
      lineHeight: '60px',
    },
  },
  h3: {
    fontWeight: 300,
    fontFamily: theme.headerFontFamily,
    margin: '0px',
    fontSize: '18px',
    lineHeight: '27px',
    letterSpacing: '0.04em',
    [theme.breakpoints.up('md')]: {
      fontSize: '24px',
      lineHeight: '36px',
    },
    [theme.breakpoints.up('lg')]: {
      letterSpacing: '0.03em',
      fontSize: '32px',
      lineHeight: '44px',
    },
  },
  h4: {
    fontWeight: 300,
    margin: '0px',
    fontFamily: theme.headerFontFamily,
    fontSize: '20px',
    lineHeight: '24px',
    letterSpacing: '0.07em',
    textTransform: 'uppercase',
    [theme.breakpoints.up('sm')]: {
      fontSize: '22px',
      lineHeight: '26px',
      letterSpacing: '0.08em',
    },
    [theme.breakpoints.up('lg')]: {
      letterSpacing: '0.1em',
      fontSize: '28px',
      lineHeight: '42px',
    },
  },
  h5: {
    fontFamily: theme.bodyFontFamily,
    fontWeight: 500,
    margin: '0px',
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '0.1em',
    textTransform: 'uppercase',
    [theme.breakpoints.up('sm')]: {
      fontSize: '16px',
      lineHeight: '24px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '18px',
      lineHeight: '27px',
    },
  },
  h6: {
    fontFamily: theme.accentFontFamily,
    fontWeight: 500,
    margin: '0px',
    fontSize: '48px',
    lineHeight: '65px',
    letterSpacing: '0.01em',
    [theme.breakpoints.up('sm')]: {
      fontSize: '64px',
      lineHeight: '87px',
    },
  },
  quote: {
    fontFamily: theme.bodyFontFamily,
    fontWeight: 300,
    margin: '0px',
    fontSize: '18px',
    lineHeight: '27px',
    letterSpacing: '0.01em',
    fontStyle: 'italic',
    [theme.breakpoints.up('sm')]: {
      fontSize: '24px',
      lineHeight: '34px',
    },
  },
  body1: {
    fontFamily: theme.bodyFontFamily,
    fontWeight: 300,
    margin: '0px',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.01em',
    [theme.breakpoints.up('sm')]: {
      fontSize: '18px',
      lineHeight: '27px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '20px',
      lineHeight: '30px',
    },
  },
  body2: {},
  subtitle: {
    fontFamily: theme.bodyFontFamily,
    fontWeight: 330,
    fontSize: '16px',
    lineHeight: '24px',
    paddingBottom: 16,
    whiteSpace: 'pre-line',
    letterSpacing: '0.01em',
    margin: '0px',
  },
  deprecatedh3ThinStrike: {
    // replace occurences with custom variant
    fontWeight: 300,
    letterSpacing: '0.04em',
    textDecorationLine: 'line-through',
    textDecorationThickness: '1.4px',
    fontSize: '18px',
    lineHeight: '27px',
    fontFamily: theme.headerFontFamily,
    margin: '0px',
    [theme.breakpoints.up('md')]: {
      fontSize: '24px',
      lineHeight: '36px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '32px',
      lineHeight: '44px',
      letterSpacing: '0.03em',
    },
  },
  button: {
    fontFamily: theme.bodyFontFamily,
    fontWeight: 420,
    fontSize: '12px',
    lineHeight: '16px',
  },
  custom: (props: TypographyProps) => {
    // Get typography style name for each screen size. Mobile first programming.
    let mobileStyles;
    let tabletStyles;
    let laptopStyles;
    let desktopStyles;
    if (props.default || props.mobile) {
      mobileStyles = props.default || props.mobile;
    }
    if (props.tablet) {
      tabletStyles = props.tablet;
    }
    if (props.smallDesktop || props.laptop) {
      laptopStyles = props.smallDesktop || props.laptop;
    }
    if (props.desktop) {
      desktopStyles = props.desktop;
    }

    // Build the styles for each breakpoint.
    // Mobile first programming.
    return {
      ...parseTypography(theme, mobileStyles),

      [theme.breakpoints.up('sm')]: {
        ...parseTypography(theme, tabletStyles),
      },
      [theme.breakpoints.up('md')]: {
        ...parseTypography(theme, laptopStyles),
      },
      [theme.breakpoints.up('lg')]: {
        ...parseTypography(theme, desktopStyles),
      },
    };
  },
});

const DECORATION = {
  strike: 'strike',
  italic: 'italic',
  link: 'link',
  upper: 'upper',
} as { [key: string]: string };

const getTextDecoration = (decoration?: string) => {
  switch (decoration) {
    case DECORATION.strike:
      return 'line-through';
    case DECORATION.link:
      return 'underline';
    default:
      return 'none';
  }
};

const WEIGHT = {
  bold: 'bold',
  semibold: 'semibold',
  regular: 'regular',
  light: 'light',
  thin: 'thin',
} as { [key: string]: string };

const getFontWeight = (weight?: string, decoration?: string) => {
  if (decoration === DECORATION.link) {
    return 500;
  }

  switch (weight) {
    case 'bold':
      return 700;
    case 'semibold':
      return 500;
    case 'light':
      return 300;
    case 'thin':
      return 250;
    case 'regular':
    default:
      return 400;
  }
};

// parseTypography:
// Expects a constant or copy-paste from figma style name; e.g. "20 Body Light Italic"
// Determine properties (for type, size, weight, decoration) from the name.
// Specially handle each type to get the actual styles.

// Note:
// Currently all the typography names follow a specific format (see Text/constants.ts)
// If we want to allow multiple parse orders, we could split on space and just try to match with constant strings,
// assuming there's no overlap... But it definitely would make search and replace more difficult if it could be in any order.
// For these reasons I decided against it
const parseTypography = (theme: Theme, typographyName: string) => {
  if (!typographyName) return {};

  // properties will be an array containing at least 1 entry: ['']
  const properties = typographyName.toLowerCase().trim().split(' ');

  // First word is currently size or btn. Bail out of that's not the case.
  // TODO(gemma): Hopefully update the btn-specific checks after meet with mackenzie

  // ignore btn for now... want to have it match same format of others.
  if (properties[0] === 'btn') {
    // Custom btn behaviors
    console.log('skipping this for now. leave it the default type');
    return {};
  }

  // btn is the only one that has a length of 2 currently.
  if (properties.length < 3) {
    return {};
  }

  // If not btn, then it's the numerical font size for the text.
  // (It'd be nicer if the first word were the type first)
  const size = properties[0];
  if (Number.isNaN(parseInt(size))) {
    return {};
  }

  if (properties[1] === 'accent') {
    return getCustomAccentStyles(theme, { size });
  }

  if (properties[1] === 'title') {
    // For Weight and Decoration, it only assigns them if it matches a value in the allowed dict.
    const props = {
      size,
      weight: WEIGHT[properties[2]] || 'light', // titles are IvyMode which we default to light
      decoration: DECORATION[properties[properties.length - 1]], // decoration comes last, after weight if weight is defined.
      typographyName, // there are a lot of custom styles for specific titles
    };
    return getCustomTitleStyles(theme, props);
  }

  if (properties[1] === 'overline') {
    // For Weight and Decoration, it only assigns them if it matches a value in the allowed dict.
    const props = {
      size,
      weight: WEIGHT[properties[2]] || 'regular',
      decoration: DECORATION[properties[properties.length - 1]], // decoration comes last, after weight if weight is defined.
    };
    return getCustomOverlineStyles(theme, props);
  }

  if (properties[1] === 'body' || properties[1] === 'tiny') {
    // For Weight and Decoration, it only assigns them if it matches a value in the allowed dict.
    const props = {
      size,
      weight: WEIGHT[properties[2]] || 'regular',
      decoration: DECORATION[properties[properties.length - 1]], // decoration comes last, after weight if weight is defined.
    };
    return getCustomBodyStyles(theme, props);
  }

  // Unsuppported type; fall back to default style.
  return {};
};

const getCustomBodyStyles = (
  theme: Theme,
  { size, weight, decoration }: TypographyStylePropsType,
) => {
  const getColor = () => {
    return decoration === DECORATION.strike
      ? theme.palette.greyDark.main
      : theme.palette.primary.main;
  };

  return {
    fontFamily: theme.bodyFontFamily,
    letterSpacing: '0.01em',
    fontSize: !!size ? `${size}px` : 'inherit',
    lineHeight: 1.5,
    // lineHeight in pixels is specifically: ({ size }: TypographyProps) => size ? `${parseInt(size) * 1.5}px` : 'inherit',
    fontStyle: decoration === DECORATION.italic ? 'italic' : 'normal',
    color: getColor(),
    fontWeight: getFontWeight(weight, decoration),
    textDecorationLine: getTextDecoration(decoration),
  };
};

const getCustomOverlineStyles = (
  theme: Theme,
  { size, weight, decoration }: TypographyStylePropsType,
) => {
  const getLetterSpacing = () => {
    switch (size) {
      case '11':
        return '0.07em';
      case '12':
        return '0.05em';
      default:
        return '0.1em';
    }
  };

  return {
    fontFamily: theme.bodyFontFamily,
    fontSize: !!size ? `${size}px` : 'inherit',
    lineHeight: 1.5,
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    fontStyle: decoration === DECORATION.italic ? 'italic' : 'normal',
    textDecorationLine: getTextDecoration(decoration),
    fontWeight: getFontWeight(weight, decoration),
    letterSpacing: getLetterSpacing(),
  };
};

const getCustomTitleStyles = (
  theme: Theme,
  { size, weight, decoration, typographyName }: TypographyStylePropsType,
) => {
  // Title has some super custom styles that are not calculable...
  // Hardcoding those here by typographyName, to overwrite any defaults.
  // Note: a few of these use the default values but it felt best to be explicit and write them out anyways
  const specialOverrides = {
    [TEXT_DS.TITLE_LIGHT_72]: { letterSpacing: '0.025em', lineHeight: '100px' },
    [TEXT_DS.TITLE_LIGHT_60]: { letterSpacing: '0.025em', lineHeight: '74px' },
    [TEXT_DS.TITLE_LIGHT_44]: { letterSpacing: '0.03em', lineHeight: '60px' },
    [TEXT_DS.TITLE_LIGHT_32]: { letterSpacing: '0.03em', lineHeight: '44px' },
    [TEXT_DS.TITLE_THIN_STRIKE_32]: {
      letterSpacing: '0.0215em',
      lineHeight: '44px',
    },
    [TEXT_DS.TITLE_LIGHT_24]: { letterSpacing: '0.04em', lineHeight: '36px' },
    [TEXT_DS.TITLE_LIGHT_ITALIC_32]: {
      letterSpacing: '0.0215em',
      lineHeight: '48px',
    },
    [TEXT_DS.TITLE_UPPER_28]: { letterSpacing: '0.1em', lineHeight: '42px' },
    [TEXT_DS.TITLE_UPPER_22]: { letterSpacing: '0.08em', lineHeight: '26px' },
    [TEXT_DS.TITLE_LIGHT_20]: { letterSpacing: '0.04em', lineHeight: '30px' },
    [TEXT_DS.TITLE_UPPER_20]: { letterSpacing: '0.07em', lineHeight: '24px' },
    [TEXT_DS.TITLE_LIGHT_18]: { letterSpacing: '0.04em', lineHeight: '27px' },
    default: {},
  };

  // Currently Decoration can only support one at a time of the DECORATION types
  // So you couldn't combine STRIKE and UPPER.
  const getTextTransform = () => {
    if (decoration === DECORATION.upper) {
      return { textTransform: 'uppercase' };
    }
    return {};
  };

  return {
    fontFamily: theme.headerFontFamily,
    fontSize: !!size ? `${size}px` : 'inherit',
    fontStyle: decoration === DECORATION.italic ? 'italic' : 'normal',
    textDecorationLine: getTextDecoration(decoration),
    fontWeight: getFontWeight(weight, decoration),
    letterSpacing: '0.1em', // default; will probably be overwritten
    lineHeight: 1.5, // default; will probably be overwritten
    color: theme.palette.primary.main,
    ...getTextTransform(),
    ...(specialOverrides[typographyName ?? 'default'] || {}), // lastly, overwrite where applicable with custom styles
  };
};

const getCustomAccentStyles = (
  theme: Theme,
  { size }: TypographyStylePropsType,
) => {
  return {
    fontFamily: theme.accentFontFamily,
    fontSize: `${size}px`,
  };
};

const Text = ({
  children,
  classes,
  className: classNamesProp,
  component,
  variant,
  ...rest
}: TypographyProps) => {
  const className = clsx(
    classes.root,
    {
      [classes.h1]: variant === 'h1',
      [classes.h2]: variant === 'h2',
      [classes.h3]: variant === 'h3',
      [classes.h4]: variant === 'h4',
      [classes.h5]: variant === 'h5',
      [classes.h6]: variant === 'h6',
      [classes.quote]: variant === 'quote',
      [classes.body1]: variant === 'body1',
      [classes.body2]: variant === 'body2',
      // These are new since last hackathon. We may want to absorb some of them into custom:
      [classes.subtitle]: variant === 'subtitle',
      [classes.deprecatedh3ThinStrike]: variant === 'deprecatedh3ThinStrike',
      [classes.button]: variant === 'button',

      // Parse by figma typography name, see Text/constants.ts
      [classes.custom]: variant === 'custom',
    },
    classNamesProp,
  );

  // We need to define the html tag for custom variants.
  // Note: If component is defined, that takes precedence over variantMapping.
  const variantMapping = {
    quote: 'span', // blockquote could work but it looks indented so I made span the default
    subtitle: 'span', // subtitle becomes a span in MUI
    custom: 'p', // or span? could be used anywhere so maybe we rely on component override?
  };

  return (
    <StylesProvider injectFirst>
      <Typography
        className={className}
        variant={
          Object.keys(variantMapping).includes(variant) ? undefined : variant
        } // dont pass variants to mui it doesnt have defined, it will cause a console error
        component={component}
        // If component is provided, it takes precedence
        // Else if mapping is provided, it uses that
        // Else it figures it out:
        // Seems like it knows naturally how to convert the default MUI variants: to use h2 for h2, that body1 and body2 > p
        variantMapping={variantMapping}
        {...rest}
      >
        {children}
      </Typography>
    </StylesProvider>
  );
};

export default withDefaults(styles, { name: 'OH-Text' })(Text);
