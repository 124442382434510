import React from 'react';
import MuiDialog from '@material-ui/core/Dialog';
import { DialogProps } from '@material-ui/core/Dialog';
import { GridSpacing } from '@material-ui/core';

import Grid from '../Grid';
import makeStyles from '../makeStyles';
import useTheme from '../useTheme';
import useMediaQuery from '../useMediaQuery';
import Text from '../Text';
import Icons from '../Icons';
import Button from '../Button';
import Theme from '../theme/ThemeType';
import { disabledColor } from '../constants/CSS';

const useStyles = makeStyles<
  Theme,
  { onSaveLoading: boolean; centerTitle: boolean; onSaveDisabled?: boolean }
>((theme) => ({
  dialog: {
    padding: '48px',
    [theme.breakpoints.down('md')]: {
      padding: '32px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '24px',
    },
    '&.MuiDialog-root': {
      margin: '-32px',
    },
  },
  headerText: ({ centerTitle }) => ({
    padding: centerTitle ? '0 0 8px 0' : '8px 0 8px 0',
    fontSize: '24px',
    lineHeight: '36px',
    letterSpacing: '0.04em',
    [theme.breakpoints.up('lg')]: {
      fontSize: '32px',
    },
  }),
  closeIcon: ({ onSaveLoading }) => ({
    cursor: onSaveLoading ? 'unset' : 'pointer',
    '& path': {
      stroke: onSaveLoading ? disabledColor : theme.palette.common.black,
    },
  }),
  customButtonContainer: {
    textAlign: 'center',
    padding: '6px 16px 30px',
  },
  saveButtonLabelColor: ({ onSaveDisabled }) => ({
    color: onSaveDisabled ? theme.palette.common.white : undefined,
  }),
}));

export interface DialogV2Props extends DialogProps {
  open: boolean;
  saveButtonLabel?: string;
  onClose?: () => void;
  closeButtonLabel?: string;
  hideBottomCloseButton?: boolean;
  title: string | JSX.Element;
  subtitle?: string;
  children?: JSX.Element;
  onSave?: () => void;
  buttonDirection?: 'row' | 'column';
  onSaveLoading?: boolean;
  onSaveDisabled?: boolean;
  closeButtonType?: string;
  saveButtonType?: string;
  contentPadding?: GridSpacing;
  customButtonComponent?: JSX.Element;
  centerTitle?: boolean;
}

export function DialogV2(props: DialogV2Props) {
  const {
    children,
    open,
    saveButtonLabel,
    onClose,
    closeButtonLabel,
    hideBottomCloseButton,
    title,
    subtitle,
    onSave,
    buttonDirection = 'row',
    onSaveLoading,
    onSaveDisabled,
    closeButtonType,
    saveButtonType,
    contentPadding,
    customButtonComponent,
    centerTitle,
    ...dialogProps
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const {
    dialog,
    headerText,
    closeIcon,
    customButtonContainer,
    saveButtonLabelColor,
  } = useStyles({
    onSaveLoading: onSaveLoading || false,
    centerTitle: centerTitle || false,
    onSaveDisabled,
  });

  const buttonGridSize = buttonDirection === 'column' ? undefined : 6;
  const buttonGridDirection =
    buttonDirection === 'column' ? 'column-reverse' : 'row';

  const onDialogClose = () => {
    if (onSaveLoading) {
      return;
    }

    if (onClose) {
      onClose();
    }
  };

  return (
    <MuiDialog
      open={open}
      onClose={onDialogClose}
      fullScreen={isMobile}
      className={dialog}
      {...dialogProps}
    >
      <div className={dialog}>
        <Grid container spacing={1}>
          <Grid container item>
            <Grid item xs={11}>
              <Text variant="h2" className={headerText}>
                {title}
              </Text>
            </Grid>
            <Grid item xs={1}>
              <Icons.Close onClick={onDialogClose} className={closeIcon} />
            </Grid>
            {subtitle && (
              <Grid item>
                <Text variant="subtitle">{subtitle}</Text>
              </Grid>
            )}
          </Grid>
          <Grid item container spacing={contentPadding || 4} direction="column">
            <Grid item>{children}</Grid>
            {!!customButtonComponent ? (
              <div className={customButtonContainer}>
                {customButtonComponent}
              </div>
            ) : (
              ((onClose && !hideBottomCloseButton) || onSave) && (
                <Grid
                  item
                  container
                  spacing={2}
                  direction={buttonGridDirection}
                >
                  {onClose && !hideBottomCloseButton && (
                    <Grid item xs={buttonGridSize}>
                      <Button
                        fullWidth
                        type={closeButtonType || 'secondary'}
                        onClick={onClose}
                        disabled={onSaveLoading}
                      >
                        {closeButtonLabel || 'Close'}
                      </Button>
                    </Grid>
                  )}
                  {onSave && (
                    <Grid item xs={buttonGridSize}>
                      <Button
                        fullWidth
                        onClick={onSave}
                        disabled={onSaveLoading || onSaveDisabled}
                        loading={onSaveLoading}
                        type={saveButtonType}
                      >
                        <span className={saveButtonLabelColor}>
                          {saveButtonLabel || 'Save'}
                        </span>
                      </Button>
                    </Grid>
                  )}
                </Grid>
              )
            )}
          </Grid>
        </Grid>
      </div>
    </MuiDialog>
  );
}
