import React, { useEffect, useMemo, useState } from 'react';
import first from 'lodash/first';
import nth from 'lodash/nth';

import Grid from '../Grid';
import ButtonV2 from '../ButtonV2';
import Text from '../Text';
import theme from '../theme';
import useMediaQuery from '../useMediaQuery';
import styled from '../styled';
import { TEXT_DS } from '../Text/constants';

const BannerContainer = styled(Grid)({
  background:
    'radial-gradient(99.65% 5791.2% at 0% 50%, #F4F3EE 0%, #F6F5F2 81.77%, #E4E4E2 100%)',
  [theme.breakpoints.up('lg')]: {
    minHeight: '500px',
    height: '100%',
  },
});

const BannerTextContainer = styled(Grid)({
  margin: 'auto',
  textAlign: 'center',
  [theme.breakpoints.up('xs')]: {
    padding: '24px 24px 40px 24px',
  },
  [theme.breakpoints.up('md')]: {
    padding: '40px',
  },
  [theme.breakpoints.up('lg')]: {
    textAlign: 'left',
    width: '50%',
    maxWidth: '800px',
    padding: '100px',
  },
});

const ButtonContainer = styled('div')`
  display: flex;
  flex-direction: row;
  margin-top: 8px;

  > * {
    margin: 0 8px !important;
  }

  @media all and (min-width: 768px) {
    margin-top: 16px;
  }

  @media all and (min-width: 1440px) {
    margin-top: 24px;
  }
`;

const ImageContainer = styled(Grid)(
  ({ bannerImage }: { bannerImage: string }) => {
    return {
      backgroundImage: `url(${bannerImage})`,
      width: '50%',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        height: '427px',
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        height: '232px',
      },
    };
  },
);

interface BannerImage {
  url: string;
  nextIndex: number;
}

export interface SplitImageHeroProps {
  images: { url: string }[];
  titleText: React.ReactNode | string;
  subText: React.ReactNode | string;
  timeBetweenImages?: number;
  /** A React component used to render a CTA link. Prefer this over `onClick` handlers for URL navigation for semantic web, accessibility, and third-party provider compatibility. */
  linkElement?: React.ReactNode;
  /** If a `linkElement` can't be used, an `onClick` handler can be provided. */
  onButtonClick?: () => void;
  /** A React component used to render a secondary CTA link. Prefer this over `onClick` handlers for URL navigation for semantic web,  accessibility, and third-party provider compatibility. */
  secondaryLinkElement?: React.ReactNode;
  buttonText?: string;
}

const SplitImageHero = (props: SplitImageHeroProps) => {
  const {
    images,
    timeBetweenImages,
    titleText,
    subText,
    buttonText,
    linkElement,
    secondaryLinkElement,
    onButtonClick,
  } = props;

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  const bannerImagesLinkedList = useMemo<BannerImage[]>(() => {
    if (Array.isArray(images)) {
      return images?.map((image, index) => ({
        url: image.url,
        nextIndex: (index + 1) % images.length, // set the last pointer to the first element
      }));
    }

    return [];
  }, [images]);

  const [currentBannerImage, setCurrentBannerImage] =
    useState<BannerImage | null>(first(bannerImagesLinkedList) || null);

  useEffect(() => {
    const interval = setInterval(() => {
      const nextImageToShow = nth(
        bannerImagesLinkedList,
        currentBannerImage?.nextIndex,
      );

      if (nextImageToShow) {
        setCurrentBannerImage(nextImageToShow);
      }
    }, timeBetweenImages || 2000); // default 2 sec

    return () => clearInterval(interval);
  }, [bannerImagesLinkedList, currentBannerImage, setCurrentBannerImage]);

  return (
    <>
      {bannerImagesLinkedList.map((image, index) => (
        <div key={`preload-image-${index}`} style={{ display: 'none' }}>
          <img src={image.url} alt="hidden preloaded image" />
        </div>
      ))}
      {isDesktop && (
        <BannerContainer container justifyContent="space-between">
          <BannerTextContainer item>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                {typeof titleText === 'string' ? (
                  <Text variant="custom" default={TEXT_DS.TITLE_LIGHT_32}>
                    {titleText}
                  </Text>
                ) : (
                  titleText
                )}
              </Grid>
              <Grid item>
                {typeof subText === 'string' ? (
                  <Text variant="custom" default={TEXT_DS.BODY_REGULAR_22}>
                    {subText}
                  </Text>
                ) : (
                  subText
                )}
              </Grid>
              <ButtonContainer>
                {linkElement && linkElement}
                {!linkElement && buttonText && onButtonClick && (
                  <Grid item>
                    <ButtonV2 color="mud" onClick={onButtonClick}>
                      {buttonText}
                    </ButtonV2>
                  </Grid>
                )}
                {secondaryLinkElement && secondaryLinkElement}
              </ButtonContainer>
            </Grid>
          </BannerTextContainer>
          {currentBannerImage?.url && (
            <ImageContainer item bannerImage={currentBannerImage?.url} />
          )}
        </BannerContainer>
      )}
      {!isDesktop && (
        <BannerContainer container direction="column">
          {currentBannerImage?.url && (
            <ImageContainer item bannerImage={currentBannerImage?.url} />
          )}
          <BannerTextContainer item>
            <Grid
              container
              direction="column"
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <Text variant="custom" default={TEXT_DS.TITLE_LIGHT_32}>
                  {titleText}
                </Text>
              </Grid>
              <Grid item>
                <Text variant="custom" default={TEXT_DS.BODY_REGULAR_18}>
                  {subText}
                </Text>
              </Grid>
              <ButtonContainer>
                {linkElement && linkElement}
                {!linkElement && buttonText && onButtonClick && (
                  <Grid item>
                    <ButtonV2 color="mud" onClick={onButtonClick}>
                      {buttonText}
                    </ButtonV2>
                  </Grid>
                )}
                {secondaryLinkElement && secondaryLinkElement}
              </ButtonContainer>
            </Grid>
          </BannerTextContainer>
        </BannerContainer>
      )}
    </>
  );
};

export default SplitImageHero;
